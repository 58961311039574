import React from 'react';
import { Modal, Card, Row, Space } from 'antd';
import { useQueryParam, StringParam } from "use-query-params";

const IndexPage: React.FC = () => {
  const [email] = useQueryParam('email', StringParam);

  return (
    <div>
      <Modal
        title="하이소사이어티"
        style={{ top: 20 }}
        visible={true}
        onOk={() => { alert('수신거부가 완료되었습니다.') }}
        okButtonProps={{ danger: true }}
        okText="수신거부"
        cancelText="아니요"
        onCancel={() => { }}
      >
        <div style={{ margin: '0 20px' }}>
          <p>{email || '-'} 이메일 수신거부 하시겠습니까?</p>
          <br />
          <Card style={{ maxWidth: 600, margin: '0 auto', padding: '10px 20px' }}>
            <div>
              <div style={{ color: '#5755d9', fontWeight: 'bold' }}>하나,</div>
              <div style={{ fontWeight: 'bold' }}>남성회원은 본인의 능력을 검증해야만 가입됩니다.</div>
            </div>
            <div style={{ marginTop: 10 }}>
              외모, 전문직, 사업가, 자산가, 고위공무원 등 남성의 경우 본인의 경제력 혹은 능력을 검증하는 서류를 제출해야 합니다. 이후 하이소사이어티 심사팀의 까다로운 인증심사 후 가입이 완료됩니다.
				  </div>
          </Card>
          <br />
          <Card style={{ maxWidth: 600, margin: '0 auto', padding: '10px 20px' }}>
            <div>
              <div style={{ color: '#ffb700', fontWeight: 'bold' }}>둘, </div>
              <div style={{ fontWeight: 'bold' }} >여성회원은 프로필 검증을 통해 가입됩니다.</div>
            </div>
            <div style={{ marginTop: 10 }}>
              아나운서, 모델, 승무원, 전문직 등 본인의 매력을 어필할 수 있는 프로필을 업로드 해주시면 평가를 통해 가입이 완료됩니다.
				  </div>
          </Card>
          <br />
          <Card style={{ maxWidth: 600, margin: '0 auto', padding: '10px 20px' }}>
            <div>
              <div style={{ color: '#e85600', fontWeight: 'bold' }}>셋, </div>
              <div style={{ fontWeight: 'bold' }}>커뮤니티, 이벤트 게시판 등 다양한 활동이 가능합니다.</div>
            </div>
            <div style={{ marginTop: 10 }}>
              인증심사를 완료한 회원은 매일 새로운 이성회원들을 소개받음과 동시에 커뮤니티, 이벤트 게시판 등을 통해 회원들간의 자유로운 교류와 만남이 가능합니다.
				  </div>
          </Card>
          <div style={{ maxWidth: 600, margin: '0 auto', textAlign: 'center' }}>
            <h3 style={{ color: '#222222' }}>" 당신의 운명의 상대를 찾아보세요 "</h3>
          </div>
          <Row justify="center" align="middle" >
            <Space size="large">
              <a href="https://highso.app/download?p=ios"><img src={'/images/appstore.svg'} /></a>
              <a href="https://highso.app/download?p=android"><img src={'/images/playstore.svg'} /></a>
            </Space>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default IndexPage